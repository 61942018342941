import { featureFlags } from "./feature-flags";

const env = {
  GOOGLE_MAPS_API_KEY: "",
  SENTRY_URL: "",
  ENVIRONMENT: "",
  CIRCLE_SHA1: "",
  GOOGLE_TAG_MANAGER: "",
  AUTH0_AUDIENCE: "",
  AUTH0_CLIENTID: "",
  AUTH0_METADATA_KEY: "",
  AUTH0_DOMAIN: "",
  INTERCOM_APP_ID: "",
  KIBANA_URL: "",
  KIBANA_ADDRESS_IDENTIFICATION: "",
  KIBANA_ADDRESS_IDENTIFICATION_12_MONTHS: "",
  KIBANA_CALL_CENTER_DASHBOARD: "",
  KIBANA_TREND_MONITORING_DASHBOARD: "",
  MUI_LICENSE_KEY: "",
  PENDO_API_KE: "",
  API_URL: "",
  WEBSOCKET_URL: "",
  CDN_URL: "",
  US_BASE_HOST: "",
  CA_BASE_HOST: "",
  ARCGIS_MAPS_API_KEY: "",
  USE_MAP_ESRI: "",
  API_URL_V1: "",
  FLATFILE_LICENSE_KEY: "",
};
Object.keys(env).forEach((key) => (env[key] = process.env[`REACT_APP_${key}`]));

//
switch (window.location.hostname.split(".")[0].substr(-2)) {
  case "ca":
    env.API_URL = process.env.REACT_APP_API_URL_CA;
    env.WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL_CA;
    break;
  case "eu":
    env.API_URL = process.env.REACT_APP_API_URL_EU;
    env.WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL_EU;
    break;
  default:
    env.API_URL = process.env.REACT_APP_API_URL_US;
    env.WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL_US;
}

if (featureFlags.get().local_api) {
  const consoleRed =
    "background: rgb(255,87,34); color: white; padding: 2px; border-radius:2px";
  console.log("%cGRANICUS: Host Compliance", consoleRed, "API local");

  env.API_URL = "http://local.hostcompliance.com:5000";
  env.WEBSOCKET_URL = "ws://local.hostcompliance.com:8080";
}

env.API_URL_V1 = env.API_URL + "/api";
env.API_URL += "/graphql";
export default env;
