const dot = require("dot-object");
import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  Input,
  InputLabel,
} from "@mui/material";
import TextMaskCustom from "./input-text-mask";
import parser from "./parser";

function View({ question, context }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || "",
  });

  useEffect(() => {
    if (question.properties.answer_default) {
      try {
        let tempValues = { ...formValues };
        tempValues.output = parser.parse(
          question.properties.answer_default,
          context
        );
        setFormValues(tempValues);
      } catch (error) {
        console.error("Error parsing answer_default:", error);
      }
    }
  }, []);

  const validate = (values) => {
    let errors = {};
    const { mask } = question.input;
    const maskLength = mask ? mask.replace(/[^9]/g, "").length : 0; // Count only digit positions

    // Required field validation
    if (question.input.required) {
      // If no mask exists, just check for non-empty input
      // If mask exists, check if number of digits are same as the mask
      if (!mask && !values.output.trim()) {
        errors.output = question.properties.locale.validation_required;
      } else if (mask && values.output.length !== maskLength) {
        errors.output = question.properties.locale.validation_required;
      }
    }

    if (
      question.input.maxlength &&
      values.output.length > question.input.maxlength
    ) {
      errors.output = question.properties.locale.validation_maximum_length;
    }

    setFormErrors(errors);

    context.assign({
      canSubmit: Object.keys(errors).length === 0,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // console.log(123, name, value);
    // console.warn(dot, context, setFormValues, validate)

    let values = { ...formValues };
    dot.str(name, value, values);
    setFormValues(values);
    validate({ [name]: value });
    context.assign({
      value: value,
    });
  };

  return (
    <div>
      {question.input.mask ? (
        <FormControl error={!!formErrors.output} fullWidth>
          <InputLabel htmlFor="text">
            {question.properties.locale.name}
          </InputLabel>
          <Input
            id="text"
            name="output"
            placeholder={question.properties.locale.placeholder}
            variant="filled"
            value={formValues.output}
            inputComponent={TextMaskCustom}
            onChange={handleInputChange}
            required={question.input.required}
            readOnly={question.input.readonly}
            autoFocus
            inputProps={{
              maxLength: question.input.maxlength || 80,
              mask: question.input.mask,
            }}
            autoComplete="off"
            aria-describedby="text-helper-text"
          />
          <FormHelperText id="text-helper-text" style={{ fontSize: "0.9rem" }}>
            {formErrors.output}
          </FormHelperText>
        </FormControl>
      ) : (
        <FormControl error={!!formErrors.output} fullWidth>
          <TextField
            name="output"
            label={question.properties.locale.name}
            placeholder={question.properties.locale.placeholder}
            variant="filled"
            value={formValues.output}
            onChange={handleInputChange}
            required={question.input.required}
            readOnly={question.input.readonly}
            autoFocus
            inputProps={{
              maxLength: question.input.maxlength || 80,
            }}
            autoComplete="off"
            aria-describedby="text-helper-text"
          />
          <FormHelperText id="text-helper-text" style={{ fontSize: "0.9rem" }}>
            {formErrors.output}
          </FormHelperText>
        </FormControl>
      )}
    </div>
  );
}

export default View;
