import "./styles.css";
import React, { useEffect, useState } from "react";
import QuestionnairesContext from "./context";
import hooks from "./hooks";
import Question from "./question";
import parser from "./parser";
import { verboseLog, indentLog, copy, namedArray } from "./util";
import { Box, CircularProgress } from "@mui/material";

function QuestionnairesProvider({
  qjson,
  questionId,
  lang,
  context,
  prefix,
  goBack,
}) {
  const shouldPersistAll = !context.admin && !["welcome"].includes(questionId);
  const persist = shouldPersistAll
    ? ["answers", "session"]
    : ["answers", "params"];
  const instance = new QuestionnairesContext(prefix, persist);
  const [tempContext, setTempContext] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);
      instance.assign(context);
      setTempContext(instance);
      let before = qjson.hooks && qjson.hooks.before ? qjson.hooks.before : [];
      if (qjson.properties.initial !== questionId) {
        if (!Array.isArray(before)) {
          before = namedArray(before, context);
        }
        before = before?.filter((item) => item["type"] !== "Action");
      }

      await hooks.chain("questionnaire.before", before, instance);
      setLoaded(true);
    };
    fetchData();
  }, [qjson.hooks]);

  const question = copy(qjson.questions[questionId]);

  if (question?.properties) {
    question.properties.locale = Object.assign(
      {},
      qjson.properties?.locale[lang || "en"],
      question.properties?.locale[lang || "en"]
    );
  }

  window.qj = {
    context: (inline) => {
      verboseLog("Context:");
      if (inline) indentLog(tempContext.get());
      else return tempContext.get();
    },
    hook: (h) => hooks.chain("console", [h], tempContext),
    logic: (subject) => parser.logic(subject, tempContext),
  };

  return !loaded ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <div>
      <section>
        <Question
          goBack={goBack}
          question={question}
          context={tempContext}
        ></Question>
      </section>
      <footer>
        <p
          dangerouslySetInnerHTML={{
            __html: parser.parse(
              question?.properties?.locale?.footer,
              tempContext
            ),
          }}
        ></p>
      </footer>
    </div>
  );
}

export { QuestionnairesProvider, QuestionnairesContext };
