const dot = require("dot-object");
import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  IconButton,
  FormControl,
  FormGroup,
  TextField,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const redTheme = createTheme({
  palette: {
    primary: {
      main: "#ff5240",
    },
    secondary: {
      main: "#ffcccc",
    },
  },
});

const GET_PLATFORMS = gql`
  {
    platforms {
      hits {
        id
        name
        pattern
        placeholder
      }
    }
  }
`;

function View({ question, context }) {
  const defaultListing = { type: "AIR", value: "" };
  const { data: platformsData } = useQuery(GET_PLATFORMS);

  //
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || [defaultListing],
  });

  useEffect(() => {
    context.assign({
      canSubmit: Object.keys(formErrors).length === 0,
    });
  }, [formErrors]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let values = { ...formValues };
    dot.str(name, value, values);

    const index = parseInt(name.split(".")[1], 10);

    if (name.includes(".type") || name.includes(".value")) {
      const selectedPlatform = getPlatformById(values.output[index]?.type);
      const urlValue = values.output[index]?.value;

      if (
        selectedPlatform &&
        urlValue &&
        !new RegExp(selectedPlatform.pattern).test(urlValue)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [`output.${index}.error`]: `Please enter a valid ${selectedPlatform.name} URL`,
        }));
      } else {
        setFormErrors((prevErrors) => {
          const remainingErrors = { ...prevErrors };
          delete remainingErrors[`output.${index}.error`];
          return remainingErrors;
        });
      }
    }

    setFormValues(values);
    context.assign({
      value: values.output,
    });
  };

  //
  const getPlatformById = (type) =>
    platformsData.platforms.hits.filter((o) => o.id === type)[0];

  const addListing = () => {
    let output = [...formValues.output];
    output.push(defaultListing);
    setFormValues({ ...formValues, output: output });
  };

  const removeListing = (i) => {
    let output = [...formValues.output];
    output.splice(i, 1);
    setFormValues({ ...formValues, output: output });
  };

  return (
    <ThemeProvider theme={redTheme}>
      <FormGroup>
        {platformsData &&
          platformsData.platforms &&
          Object.keys(platformsData.platforms.hits).length > 0 &&
          formValues.output.map((l, i) => {
            const isRemovable =
              formValues.output.length >
              (question.input.required === false ? 0 : 1);

            return (
              <Stack direction={{ xs: "column", sm: "row" }} key={i}>
                {isRemovable && (
                  <div style={{ marginRight: "10px" }}>
                    <IconButton
                      variant="outlined"
                      className="back"
                      tabIndex={-1}
                      onClick={() => removeListing(i)}
                    >
                      <span
                        style={{
                          width: "15px",
                          height: "15px",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        X
                      </span>
                    </IconButton>
                  </div>
                )}

                <FormControl fullWidth sx={{ flex: 0.3, padding: "2px" }}>
                  <InputLabel id={`output.${i}-type-label`} shrink required>
                    {question.properties.locale.type_placeholder}
                  </InputLabel>
                  <Select
                    labelId={`output.${i}-type-label`}
                    id={`output.${i}-type`}
                    name={`output.${i}.type`}
                    inputProps={{
                      inputref: `ref-${i}-type`,
                    }}
                    value={formValues.output[i].type}
                    onChange={handleInputChange}
                    required
                    readOnly={question.input.readonly}
                    aria-describedby={`output.${i}-type-helper-text`}
                    label={question.properties.locale.type_placeholder}
                  >
                    {platformsData.platforms.hits.map((p) => {
                      return (
                        <MenuItem
                          key={`output.${i}-type-option-${p.id}`}
                          value={p.id}
                        >
                          {p.name}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value="OTHER">
                      {question.properties.locale.other_placeholder}
                    </MenuItem>
                  </Select>
                  <FormHelperText
                    id={`output.${i}-type-helper-text`}
                    style={{ fontSize: "0.9rem" }}
                  >
                    {formErrors.text}
                  </FormHelperText>
                </FormControl>

                <FormControl error fullWidth sx={{ flex: 0.7, padding: "2px" }}>
                  <TextField
                    id={`output.${i}-value`}
                    name={`output.${i}.value`}
                    label={
                      formValues.output[i].type === "OTHER"
                        ? question.properties.locale.default_placeholder
                        : `${
                            getPlatformById(formValues.output[i].type)?.name ||
                            ""
                          } ${question.properties.locale.url_placeholder}`
                    }
                    placeholder={
                      formValues.output[i].type === "OTHER"
                        ? "Enter URL"
                        : getPlatformById(formValues.output[i].type)
                            ?.placeholder || ""
                    }
                    variant="filled"
                    value={formValues.output[i].value}
                    onChange={handleInputChange}
                    required
                    readOnly={question.input.readonly}
                    autoFocus
                    inputProps={{
                      maxLength: question.input.maxlength
                        ? question.input.maxlength
                        : 80,
                      inputref: `ref-${i}-value`,
                    }}
                    autoComplete="off"
                    aria-describedby={`output.${i}-value-helper-text`}
                  />
                  {formErrors[`output.${i}.error`] && (
                    <FormHelperText
                      id={`output.${i}-value-helper-text`}
                      style={{ fontSize: "0.9rem", color: "red" }}
                    >
                      {formErrors[`output.${i}.error`]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            );
          })}

        <nav dir={question.properties.locale.dir}>
          <Button
            variant="contained"
            className="back"
            tabIndex={-1}
            onClick={addListing}
            sx={{
              borderRadius: "50px",
            }}
          >
            {question.properties.locale.add_listing}
          </Button>
        </nav>
      </FormGroup>
    </ThemeProvider>
  );
}

export default View;
